import type { GtmSupport } from "@gtm-support/vue-gtm"

function gtmAddToCart($csGtm: GtmSupport, itemsAdded: OrderLineItem[]) {
  $csGtm?.trackEvent({
    event: "addToCart",
    ecommerce: {
      add: {
        products: itemsAdded.map((item) => {
          return {
            id: item.variant.sku,
            name: item.variant.productName,
            category: item.variant.googleAnalyticsCategory,
            variant: item.variant.name,
            price: item.price,
            quantity: item.quantityAdded,
          }
        }),
      },
    },
    skus_selected: itemsAdded.map((item) => item.variant.sku),
    total_value_added_to_cart: itemsAdded.reduce((sum, item) => {
      return sum + item.quantityAdded * parseInt(item.price)
    }, 0),
  })
}

function gtmRemoveFromCart($csGtm: GtmSupport, itemsRemoved: OrderLineItem[]) {
  $csGtm?.trackEvent({
    event: "removeFromCart",
    ecommerce: {
      remove: {
        products: itemsRemoved.map((item) => {
          return {
            id: item.variant.sku,
            name: item.variant.productName,
            category: item.variant.googleAnalyticsCategory,
            variant: item.variant.name,
            price: item.price,
            quantity: item.quantity,
          }
        }),
      },
    },
    total_value_removed_to_cart: itemsRemoved.reduce((sum, item) => {
      return sum + item.quantity * parseInt(item.price)
    }, 0),
  })
}

export function trackAddToCart($csGtm: GtmSupport, lineItems: OrderLineItem[]) {
  const itemsAdded = lineItems?.filter((li: any) => li.quantityAdded > 0) || []

  if (!itemsAdded.length) {
    return
  }

  gtmAddToCart($csGtm, itemsAdded)
}

export function trackRemoveFromCart(
  $csGtm: GtmSupport,
  lineItems: OrderLineItem[],
) {
  gtmRemoveFromCart($csGtm, lineItems)
}

export function trackUpdateCart(
  $csGtm: GtmSupport,
  payload: { lineItem: OrderLineItem; change: number },
) {
  const item = payload.lineItem
  const change = payload.change

  if (change > 0) {
    gtmAddToCart($csGtm, [{ ...item, quantityAdded: change }])
  } else if (change < 0) {
    gtmRemoveFromCart($csGtm, [{ ...item, quantity: Math.abs(change) }])
  }
}

export function trackShippingEstimate(
  $csGtm: GtmSupport,
  order: SpreeCartOrder,
) {
  $csGtm?.trackEvent({
    event: "shippingEstimate",
    order_number: order?.number,
    shipping_estimate_zipcode: order?.shippingQuoteZipcode,
    shipping_estimate_shipments: order?.shipments,
  })
}
